import React, { useState,useContext,useRef,useEffect } from 'react';
import '../styles/__Chatsection.css'
import Cookies from 'js-cookie';
import { IMessage } from '../Interfaces/IMessage'
import { UserContext } from '../context/UserContext'
import DOMPurify from 'dompurify'
import { marked } from 'marked'; 

interface ChatSectionProps {
  allChats: { id: number, messages: { sender: string, content: string, timestamp: string, chat_thread: string }[] }[];
  selectedChatId: number | null;
  
}
interface IResponse {
  [key: string]: { date: string; data: IData }[];
}
interface IData {
  [key: string]: number;
}
const Chat_section:React.FC<ChatSectionProps> = ({allChats, selectedChatId }) => {
  
   const [userMessage, setUserMessage] = useState('');
   const [loading, setLoading] = useState<boolean>(false);
   const csrfToken = Cookies.get('csrftoken');
   const [conversation, setConversation] = useState<IMessage[]>([]);
   const {getSiteName,getDaterange, user} = useContext(UserContext)
   const messagesEndRef = useRef<HTMLDivElement>(null);
   const mapSenderToRole = (sender: string) => {
    if (sender == 'assistant')
        return 'assistant';
    else
        return 'user';
    
  };
  

   const handleKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      // e.preventDefault();
      handleUserMessage(userMessage);
      
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const sampleQuestions = [
    "1. Tell me the performance of all inverters?",
    "2. How many inverters we have on site?",
    "3. What is the power data of all inverters on 3rd June?",
    "4. What measures need to take if grid voltage is too low?",
  ];



   const handleUserMessage = async (userMessage: any) => {
    if (loading) {
      return
    }
    const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
    const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
    const chatId = sessionStorage.getItem('chatId')
    if(chatId==null)
    { 
      const response = await fetch('api/start-new-chat/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          org_id: ActiveOrgId,
          site_id: ActiveSiteId,
          username: user.username
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const chatiddata = await response.json();
        const chatId = chatiddata.chat_id;
        sessionStorage.setItem('chatId',chatId.toString());
    }
    setLoading(true); 
    const headers = new Headers();
          headers.append('Content-Type', 'application/json',);
          headers.append('X-CSRFToken', csrfToken || '');
  
      const newUserMessage: IMessage = { sender: 'user', content: userMessage};
      setConversation((prevConversation) => [...prevConversation, newUserMessage]);
      console.log(conversation)
      setUserMessage('');
     try {
        const user_name = user.username
        const url = 'api/'+ActiveOrgId+'/'+ActiveSiteId+'/'+'chatpage'
        console.log(url)
        const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({userMessage,conversation,user_name,chatId}),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
          
        }
        console.log(response)
        const htmlContent = await response.json();
        
        console.log(htmlContent.task_id); 
        setTaskId(htmlContent.task_id);
        
        } catch (error) {
        console.error('Error:', error);
      }finally {
        setLoading(false);
      }
  }

  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // //   if (messagesEndRef.current) {
  // //     messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  // // }
  // }, [conversation,userMessage]);






  useEffect(() => {
    (async () => {

    if (conversation)
      {
      const sitename = await getSiteName()
      const run_daterange = await getDaterange()
      const daterange = JSON.parse(sessionStorage.getItem('daterange') || '[]');
      const last_timestamp = daterange.last_timestamp
      const first_timestamp = daterange.first_timestamp
      
      if(daterange=='Site does not exist in database')
  
        {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, There is no data file available for this site. Please upload the data file' )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
      
      else if(daterange=='This site is not communicating currently')

        {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, This site is not communicating currently' )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}

      
      else if(daterange=='No data available for this site or site is not communicating currently')
  
        {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, No data available for this site or site is not communicating currently' )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
        
      else if (last_timestamp && first_timestamp)
        {
        const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, Data is available for ' +sitename+ ' from ' +first_timestamp+ ' to ' +last_timestamp )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
      }
    })();
  }, []);
  
  
  const [taskId, setTaskId] = useState(null);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState('');

  
 useEffect(() => {
   if (!taskId) return;
   

   const interval = setInterval(async () => {
         const response = await fetch(`api/task-status/${taskId}/`);
         console.log(response)
         const data = await response.json();
         console.log(data);
         console.log(taskId)
         // setStatus(data.status);
         if (data.status === "SUCCESS") {
            console.log("got the result");
             // setResult(data.result);
             console.log('data:',data)
             const sanitizedHtml = DOMPurify.sanitize(data.result);
             const newBotMessage: IMessage = { sender: 'assistant', content: sanitizedHtml };
            setConversation((prevConversation) => [...prevConversation, newBotMessage])
             clearInterval(interval);
         }
         if (data.status === "FAILURE")
         {
           console.log("FAIL");
           clearInterval(interval);
         }

   }, 1000);

   return () => clearInterval(interval);
 }, [taskId]);

 useEffect(() => {
  if (selectedChatId !== null) {
    console.log(allChats)
    const selectedChat = allChats.find(chat => chat.id === selectedChatId);
    if (selectedChat) {
      setConversation(selectedChat.messages);
    }
  }
  }, [selectedChatId, allChats]);



  const [sanitizedContent, setSanitizedContent] = useState<string[]>([]);
  
  useEffect(() => {
    
    const sanitizeMessages = async () => {
      const sanitizedMessages = await Promise.all(
        conversation.map(async (message) => {
          // Step 1: Trim excessive newlines and extra spaces from the content
          //console.log(message.content)
          const cleanedContent = message.content
          .replace(/(\d+)\n(\d+\.)/g, '$1 $2') 
          .replace(/\n\s*\n+/g, '\n')// Handle cases like 13,452,352\n2.
          .replace(/\n+/g, ' ')
          .replace(/\t/g, ' - ')
           // Replace other newlines with space
          .trim(); // Replace multiple \n with single \n
          //console.log('content:',cleanedContent)
          // Step 2: Convert Markdown to HTML using `marked`
          const rawHtml =await marked.parse(cleanedContent);  // Convert markdown to HTML
          
          // Debugging: Log the rawHtml to check the conversion result
          //console.log("Raw HTML from marked: ", rawHtml);

          // Step 3: Sanitize the HTML using DOMPurify to avoid XSS attacks
          return DOMPurify.sanitize(rawHtml);
        })
      );
      setSanitizedContent(sanitizedMessages);
    };

    sanitizeMessages();
  }, [conversation]);

  return (
    <div className='Chat_section'>
    <div 
      className="chat-container" 
      ref={messagesEndRef}  // Step 3: Attach the reference to the chat container
      style={{ overflowY: 'auto' }}  // Example: scrollable area
    >
        <div className='msgbox' >
        <div className='chat-messages'>
        {conversation.map((message, index) => (
        <div key={index} className={`message ${mapSenderToRole(message.sender)}`}>
          {message.sender === 'assistant' ? (
            // Display pre-sanitized content from state
            <div dangerouslySetInnerHTML={{ __html: sanitizedContent[index] }}></div>
           
          ) : (
            <div>{message.content}</div>
          )}
        </div>
      ))}
            {/* {conversation.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                    <ResponseComponent response={message.content} />
                </div>
            ))} */}
           
          </div>
        </div>
        </div>
    <div className="bottomsection">
        <div className="messagebar">
            <input type='text' placeholder='Type a message...'
            onChange={(e)=> setUserMessage(e.target.value)}
            value={userMessage} disabled={loading} onKeyDown={handleKeyPress}/>
            <svg onClick={handleUserMessage} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
            </svg>


        </div>
    </div>
    <div
      className="floating-chat-icon"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={require('../assets/question_mark.png')} alt="chat icon" />
      {isHovered && (
        <div className="chat-dialog-box">
          <h4>Ask Questions:</h4>
          <ul>
            {sampleQuestions.map((question, index) => (
              <li key={index}>{question}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
        

    </div>

  )
}

export default Chat_section
