import React from 'react';

type PopupProps = {
  isOpen: boolean;
  message: string;
  isSuccess?: boolean; // New prop to handle success messages
  onConfirm?: () => void;
  onCancel?: () => void;
};

const Popup: React.FC<PopupProps> = ({
  isOpen,
  message,
  isSuccess = false,
  onConfirm,
  onCancel,
}) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-card">
        <p>{message}</p>
        {!isSuccess && ( // Show buttons only for confirmation, not success messages
          <div className="popup-buttons">
            <button className="confirm-btn" onClick={onConfirm}>
              Confirm
            </button>
            <button className="cancel-btn" onClick={onCancel}>
              Cancel
            </button>
          </div>
        )}
        {isSuccess && ( // Show only "Close" button for success messages
          <div className="popup-buttons">
            <button className="confirm-btn" onClick={onCancel}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
